.noDataMessageContainer {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.message {
  font-size: 17px;
  font-weight: 600;
  color: #262338;
  margin: 20px 0 10px 0;
}

.description {
  font-size: 13px;
  font-weight: 500;
  color: #6e7191;
  margin: 0;
}
