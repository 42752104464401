.popover {
  padding: 16px;
  background: #ffffff;
  border: 1px solid #f4f4f6;
  box-shadow: 0px 4px 28px rgba(120, 120, 120, 0.25);
  border-radius: 8px;
}

.menu {
  padding: 16px;
  width: 290px;
  height: 374px;
  display: flex;
  flex-direction: column;

  h2 {
    font-weight: 600;
    font-size: 15px;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    color: #6e7191;
    margin: 8px 0 8px 0;
  }
}

.formControl {
  width: 100%;
}

.inputLabel {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #a0a3bd;
  margin-top: 8px;
  margin-bottom: 4px;
}

.input {
  width: 100%;
  height: 38px;
  border-color: #d9dbe9;
  border-radius: 8px;

  & input {
    font-size: 13px;
    font-weight: 500;
    color: #6b7280;
    padding: 10px 16px;
    height: 38px;
    border-radius: 8px;
    background: #eff0f7;
    box-sizing: border-box;

    &:disabled {
      background: #e3e4ec;
      cursor: not-allowed;
    }
  }
}

.checkbox {
  padding: 7px 8px 5px 10px;
}

.checkboxLabel {
  font-weight: 500;
  font-size: 13px;
  color: #4e4b66;
}

.actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.action {
  width: 125px;
  height: 44px;
  border-radius: 8px;
}
