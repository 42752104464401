.createEditTemplateContainer {
  padding: 24px;
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background: #f5f5f5;
  overflow-y: auto;
}
