.tabs {
  box-shadow: inset 0px -1px 0px #d2d6df;
}

.flexContainer {
  padding-left: 16px;
}

.tab {
  font-weight: 500;
  margin-right: 20px;
  padding: 12px 0;
  width: auto;
  min-width: 20px;
}

.counter {
  border-radius: 16px;
  background-color: #E0F5FB;
  padding: 2px 8px;
  font-size: 13px;
}

.disabledCounter {
  border-radius: 16px;
  background-color: #F7F7FC;
  padding: 2px 8px;
  font-size: 13px;
}

.tableHead {
  & th {
    font-size: 13px;
    font-weight: 500;
    color: #a0a3bd;
    padding: 16px 6px;
    border: none;
    box-shadow: inset 0px -1px 0px #d2d6df;
    vertical-align: middle;

    &:first-child {
      padding-left: 32px;
    }

    & div {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    & div:hover {
      cursor: pointer;
    }
  }
}

.container {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.tableBody {
  & tr {
    box-shadow: inset 0px -1px 0px #d2d6df;

    & td {
      font-size: 13px;
      color: #212529;
      padding: 16px 16px 16px 8px;
      border: 0;
      min-width: 80px;
    }

    & td:first-child {
      padding-left: 30px;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.statusBadge {
  font-size: 13px;
  text-align: center;
  padding: 6px 10px;
  height: 34px;
  width: auto;
  border-radius: 20px;
}
