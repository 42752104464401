.alarmsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.alarmsHeaderContainer {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;

  & h1 {
    font-size: 20px;
    line-height: 38px;
  }

  & path {
    fill: #14142B;
  }

  & span {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & span:hover {
    cursor: pointer;
  }
}

.alarmsContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.alarmsFooter {
  padding: 20px 20px 40px;

  button {
    width: 100%;
    height: 48px;
    border-color: #EAEEF3;
  }
}