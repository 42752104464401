.menu {
  padding: 0;
}

.menuItem:last-child {
  margin: 12px 0;
}

.activeMenuItem {
  background: #E0F5FB;
}

.menuLabel {
  font-weight: 600;
  color: #a0a3bd;
}

.activeMenuLabel {
  color: #158aa4;
}

.activeMenuButton {
  border-left: 5px solid #158aa4;
}

.menuIcon {
  margin: 10px 15px 10px 10px;
  min-width: 20px;
}

.activeMenuIcon {
  margin: 10px 15px 10px 5px;

  & svg {
    & path {
      fill: #158aa4;
    }

    & rect {
      & ~ path {
        fill: white;
      }
      fill: #158aa4;
    }
  }
}
