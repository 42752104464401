.treatmentPlansContainer {
  padding: 12px 20px;
  width: 100%;
  height: auto;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;
}

.header {
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.dateInput {
  width: 274px;
  height: 42px;
  border-color: #D1D5DB;
  border-radius: 8px;

  &>div {
    border-color: #D1D5DB;
    border-radius: 8px;
    background: #F9FAFB;
  }

  & input {
    font-size: 13px;
    font-weight: 500;
    color: #6B7280;
    padding: 10px 0 10px 16px;
    border-radius: 8px;
    background: #F9FAFB;
  }
}

.treatmentPlans {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.treatmentPlan {
  padding: 13px;
  width: 370px;
  height: 48px;
  background: #fcfcfc;
  border: 1px solid #d9dbe9;
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  p {
    font-weight: 600;
    font-size: 13px;
    margin: 0;
  }

  svg {
    position: absolute;
    right: 13px;
    top: 13px;
  }

  &.selected {
    color: #158aa4;
    border: 1px solid #158aa4;

    svg path {
      fill: #158aa4;
    }
  }
}

.noPlansMessage {
  height: 75%;
}

.applyContainer {
  margin-top: 24px;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}

.applyAction {
  width: 220px;
  height: 44px;
}