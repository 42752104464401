.container {
  height: 496px;
  width: 891px;
  border-radius: 8px;
  position: relative;
  background-color: #010202;
  display: flex;
  justify-content: center;
  align-items: center;

  .closeIcon {
    color: #EFF0F6;
    top: 21px;
    right: 21px;
    position: absolute;
    cursor: pointer;
  }
}
