.container {
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: 1px solid #eff0f7;
  border-radius: 8px;

  margin-top: 50px; // TODO: delete
}

.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  .controlsLeftPart {
    gap: 12px;
    display: flex;
    flex-direction: column;

    .exerciseActivation {
      display: flex;
      gap: 8px;
    }
  }

  .controlsRightPart {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .exerciseActivationLabel {
      color: rgba(160, 163, 189, 1);
    }
  }
}

.groups {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  align-items: flex-start;
}
