.fullPageContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background: #F5F5F5;
  overflow: hidden;
}

.pageContainer {
  margin: 24px;
  width: calc(100% - 250px);
  height: calc(100% - 50px);
  background: white;
  border-radius: 12px;
  position: relative;
}