.tabs {
  box-shadow: inset 0px -1px 0px #d2d6df;
}

.flexContainer {
  padding-left: 16px;
}

.tab {
  font-weight: 500;
  margin-right: 5px;
  padding: 12px 10px;
  width: auto;
  min-width: 20px;
}

.indicator {
  height: 3px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}