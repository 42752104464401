.alarms {
  padding: 0px 0px 4px;
  min-width: 36px;
  max-width: 50px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-bottom: 1px dashed #9197aa;
}

.activeAlarm {
  & path {
    fill: #E56900;
  }
  cursor: pointer;
}
