.errorPageContainer {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 12px;
}

.errorCode {
  font-weight: 400;
  font-size: 60px;
  line-height: 72px;
  color: #818C8B;
  padding: 0;
  margin: 0;
}

.errorMessage {
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;
  color: #2D4642;
  margin: 18px 0;
  width: auto;
}

.goToAction {
  width: 217px;
  height: 52px;
  margin-top: 48px;
}
