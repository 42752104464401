/* @import-normalize; */

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #212529;
  margin: 0;
}

h2 {
  font-size: 17px;
  font-weight: 600;
  line-height: 34px;
  color: #262338;
  margin: 0;
}

/* Recharts customization */
.recharts-cartesian-axis-tick-value, .recharts-label {
  font-weight: 500;
  font-size: 13px;
  color: #6E7191;
}