.exerciseItem {
  border-radius: 8px;
  width: 154px;
  height: 96px;
  border-color: transparent;
  position: relative;
  cursor: pointer;

  img {
    display: block;
    border-radius: 8px;
    border-color: transparent;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: 13px;
    right: 13px;
    color: #EFF0F6;
  }
}

.textPreview {
  object-fit: cover;
  object-position: 0 100%;
}
