.alarmContainer {
  display: flex;
  border-bottom: 1px solid #EFF0F7;
  padding: 16px 0px;

  & .iconContainer {
    padding: 0px 16px;

    & span {
      background-color: #FFF8F2;
      padding: 10px;
      border: 1px solid #FFAC66;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      & path {
        fill: #E56900;
      }
    }
  }

  & .content {
    margin-right: 16px;

    & p {
      margin: 0px;
      font-size: 13px;
      line-height: 22px;
      letter-spacing: 0.25px;
      font-weight: 500;
    }

    & p:first-of-type {
      color: #262338;
    }

    & p:last-of-type {
      color: #A0A3BD;
    }

    & button {
      width: 120px;
      height: 30px;
      font-size: 12px;
      line-height: 14px;
      margin-top: 12px;
      background-color: #E0F5FB;
    }

    & h5 {
      margin: 12px 0px 0px;
    }
  }
}
