.controlsContainer {
  padding: 20px 20px 8px 20px;
}

.controlsContainer,
.controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.controls {
  gap: 20px;
}

.searchInput {
  background: #fafbfc;
  width: 350px;

  & input {
    font-size: 13px;
    font-weight: 500;
    padding: 7px 11px;
  }
}

.createPlan {
  width: 132px;
}