.viewPatientContainer {
  padding: 24px;
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background: #f5f5f5;
  overflow-y: auto;
}

.alarmsDrawer {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #FFF;
  width: 30vw;
}

.tabs {
  box-shadow: inset 0px -1px 0px #d2d6df;
  height: 500px;
}

.flexContainer {
  padding-left: 16px;
  //height: 500px;
}

.tab {
  font-weight: 500;
  margin-right: 5px;
  padding: 12px 10px;
  width: auto;
  min-width: 20px;
}

.indicator {
  height: 3px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
