.treatmentTimelineContainer {
  width: 100%;
  height: calc(100% - 210px);
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;

  h2 {
    padding: 20px 20px 10px 20px;
  }
}

.tableContainer {
  max-height: calc(100% - 65px);
}

.tableHead {
  & th {
    font-size: 13px;
    font-weight: 500;
    color: #a0a3bd;
    padding: 16px 6px;
    border: none;
    box-shadow: inset 0px -1px 0px #D2D6DF;

    &:first-child {
      padding-left: 32px;
    }
  }
}

.tableBody {
  & tr {
    box-shadow: inset 0px -1px 0px #D2D6DF;

    & td {
      font-size: 13px;
      color: #212529;
      padding: 16px 16px 16px 8px;
      border: 0;
    }

    & td:first-child {
      padding-left: 30px;
    }

    & td:last-child {
      padding-right: 30px;
      max-width: 120px;
    }
  }
}
