.preparation {
  color: #7897ff;
  background: #f2f5ff;
  border: 1px solid #9bb2fd;
}

.planned {
  color: #4e4b66;
  background: #f7f7fc;
  border: 1px solid #a0a3bd;
}

.active {
  color: #045460;
  background: #e0f5fb;
  border: 1px solid #0f768b;
}

.finished {
  color: #a8a3a3;
  background: #fafafa;
  border: 1px solid #cdcbcb;
}
