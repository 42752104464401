.interval {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;

  span {
    font-weight: 500;
    font-size: 13px;
    color: #4E4B66;
  }
}
