.treatmentTimelineContainer {
  width: 100%;
  flex: 1;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;

  h2 {
    padding: 20px 20px 5px 20px;
  }
}

.tableHead {
  & th {
    font-size: 13px;
    font-weight: 500;
    color: #a0a3bd;
    padding: 16px 6px;
    border: none;
    box-shadow: inset 0px -1px 0px #d2d6df;

    &:first-child {
      padding-left: 32px;
    }
  }
}

.tableBody {
  & tr {
    box-shadow: inset 0px -1px 0px #d2d6df;

    & td {
      font-size: 13px;
      color: #212529;
      padding: 20px 16px 20px 8px;
      border: 0;
    }

    & td:first-child {
      padding-left: 30px;
    }

    & td:last-child {
      padding-right: 30px;
      max-width: 120px;
    }
  }
}

.footer {
  padding: 13px 40px 10px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.addPeriod {
  color: #158aa4;

  & svg path {
    fill: #158aa4;
  }
}

.formControl {
  width: 100%;
  min-width: 180px;
  max-width: 300px;
}

.input {
  height: 38px;
  border-color: #d9dbe9;
  border-radius: 8px;

  & input {
    font-size: 13px;
    font-weight: 500;
    color: #6b7280;
    padding: 10px 16px;
    height: 38px;
    border-radius: 8px;
    background: #eff0f7;
    box-sizing: border-box;
  }
}

.helper {
  margin: 0;
  position: absolute;
  top: 38px;
}

.calculatedData {
  display: flex;
  justify-content: unset;
  gap: 60px;
  padding: 20px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #D9DBE9;
}
