.treatmentPlanDetailsContainer {
  padding: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;

  h2 {
    width: 100%;
  }
}
