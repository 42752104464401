.createEditPatientContainer {
  padding: 24px;
  width: calc(100% - 200px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  background: #F5F5F5;
  overflow-y: auto;
}