.controlsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigationControls {
  display: flex;
  gap: 12px;
}

.back {
  width: 36px;
  height: 36px;
  background: #e0f5fb;
  border: 1px solid #b2e6f4;
  border-radius: 8px;
}

.controls {
  display: flex;
  gap: 8px;
}

.control {
  width: 120px;
  height: 44px;
}
