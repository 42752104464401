.infoBlock {
  height: auto;
  
  & p:first-child {
    font-weight: 500;
    font-size: 13px;
    color: #a0a3bd;
    margin: 0;
  }

  & p:last-child {
    font-weight: 500;
    font-size: 13px;
    color: #262338;
    margin: 6px 0 0 0;
  }
}
