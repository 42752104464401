.label {
  fill: rgb(110, 113, 145);
  color: rgb(110, 113, 145);
  font-size: 13px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
}

.labelY {
  font-weight: 500;
  font-size: 13px;
}
