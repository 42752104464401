.timeContainer {
  display: flex;
  gap: 4px;
  align-items: center;

  .timeInput {
    position: relative;

    input {
      padding: 8px 10px;
      border-radius: 8px;
      border: 1px solid #D9DBE9;
      background: #F9FAFB;
      width: 69px;
      color: #4E4B66;

      // Disable arrows
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type=number] {
        -moz-appearance: textfield;
      }
    }

    span {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #6E7191;
    }
  }
}
