.patientInfoContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
}

.personalInfo {
  padding: 18px 16px 16px 20px;
  width: 384px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;

  .columns {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .column {
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
}

.treatmentInfo {
  padding: 18px 20px 10px 20px;
  width: calc(100% - 400px);
  height: auto;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .rows {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .row {
    padding-right: 45px;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

h2 {
  width: 100%;
}

.infoBlock {
  & p:first-child {
    font-weight: 500;
    font-size: 13px;
    color: #a0a3bd;
    margin: 0;
  }

  & p:last-child {
    font-weight: 500;
    font-size: 13px;
    color: #262338;
    margin: 6px 0 0 0;
  }
}

.fullName {
  font-weight: 500;
  font-size: 13px;
  color: #262338;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusBadge {
  font-size: 13px;
  text-align: center;
  margin-top: 4px;
  padding: 2px 10px;
  height: 26px;
  width: auto;
  display: flex;
  border-radius: 20px;
}

.textBlock {
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-wrap;
}
