.group {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border: 1px solid;
  border-radius: 8px;
  min-width: 209px;
  flex: 1;

  .groupDescription {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-self: flex-start;

    .assignedLabel {
      color: rgba(160, 163, 189, 1);
    }
  }

  .manageGroupButton {
    border: 1px solid rgba(217, 219, 233, 1);
    border-radius: 8px;
    padding: 8px 12px;
    background: none;
    cursor: pointer;

    &:hover {
      background-color: rgba(217, 219, 233, 1);
    }
  }

  .exerciseList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}
