.container {
  background-color: #F5F5F5;
  padding: 16px;
  text-align: justify;
  color: #14142B;

  & h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    text-align: center;
    margin-bottom: 32px;
    letter-spacing: 1px;
  }

  & h2 {
    margin: 16px 0px;
    font-weight: 700;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 1px;
  }

  & h3 {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 1px;
  }
  
  & p {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
    letter-spacing: .75px;
  }

  & a {
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
    line-height: 34px;
    letter-spacing: .75px;
    color: #14142B;
  }
}
