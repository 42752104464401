.paginationContainer {
  width: calc(100% - 18px);
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
}

.paginationList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.pageButton, .nextPrevious {
  padding: 6px 12px;
  background: white;
  border: 1px solid #D1D5DB;
  border-left: none;
  border-radius: 0;
  cursor: pointer;
}

.selected {
  background: #E0F5FB;
}

.next {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.previous {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left: 1px solid #D1D5DB;
}