.controlsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.navigationControls {
  padding-top: 10px;
  display: flex;
  gap: 12px;
}

.back {
  width: 36px;
  height: 36px;
  background: #e0f5fb;
  border: 1px solid #b2e6f4;
  border-radius: 8px;
}

.controls {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.password {
  background: #FFFFF2;
  border: 1px solid #F3F3B2;
}

.report {
  background: #F2FCFF;
  border: 1px solid #E6F8FF;
}

.link {
  background: #F2F5FF;  
  border: 1px solid #E5EBFE;
}

.edit {
  background: #E0F5FB;
  border: 1px solid #B2E6F4;
}

.delete {
  background: #FFF2F2;
  border: 1px solid #CC0000;
}

.alarms {
  background: #FFF8F2; 
  border: 1px solid #FFF1E6;
}

.externalLink {
  display: flex;
}

.disabledExternalLink {
  & path {
    fill: #8997c3;
  }
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.linkLoadingWrapper {
  display: flex;
}
