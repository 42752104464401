.treatmentPlanDetailsContainer {
  padding: 20px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #ffffff;
  border: 1px solid #eff0f7;
  border-radius: 8px;
}

.treatmentPlanDetails, .calculatedData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.calculatedData {
  justify-content: unset;
  gap: 60px;
}

.formControl {
  width: calc(50% - 16px);
  min-width: 160px;
  max-width: 300px;
}

.label {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #a0a3bd;
  margin-top: 0;
  margin-bottom: 4px;

  & span {
    color: #cc0000;
  }
}

.input {
  width: 100%;
  height: 38px;
  border-color: #d9dbe9;
  border-radius: 8px;

  & input {
    font-size: 13px;
    font-weight: 500;
    color: #6b7280;
    padding: 10px 16px;
    height: 38px;
    border-radius: 8px;
    background: #eff0f7;
    box-sizing: border-box;
  }
}

.helper {
  margin: 0;
}

.selectRoot {
  border-radius: 8px;
  height: 38px;

  & svg {
    margin-top: 4px;
  }
}

.selectInput {
  font-size: 13px;
  font-weight: 500;
  color: #6b7280;
  padding: 8px 16px;
  width: 100%;
  max-height: 38px;
  border-color: #d9dbe9;
  border-radius: 8px;
  background: #eff0f7;
  box-sizing: border-box;
}
