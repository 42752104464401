.tableContainer {
  max-height: calc(100% - 95px);
}

.table {
  margin-top: 10px;
}

.tableHead {
  & th {
    font-size: 13px;
    font-weight: 500;
    color: #a0a3bd;
    padding: 16px 6px;
    border: none;
    box-shadow: inset 0px -1px 0px #d2d6df;

    &:first-child {
      padding-left: 32px;
    }
  }
}

.tableBody {
  & tr {
    box-shadow: inset 0px -1px 0px #d2d6df;

    & td {
      font-size: 13px;
      color: #212529;
      padding: 16px 16px 16px 8px;
      border: 0;
    }

    & td:first-child {
      padding-left: 30px;
    }

    & td:last-child {
      padding-right: 30px;
      max-width: 120px;
    }
  }
}

.deleteAction {
  margin-right: 15px;
}