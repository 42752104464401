.operationTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.thighs {
  height: 14px;
}

.shins {
  display: flex;
  gap: 3px;
}

.operationName {
  font-weight: 500;
  font-size: 13px;
  color: #262338;
}

.selected {
  & path {
    fill: #9197AA;
  }
}
