.controlsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navigationControls {
  display: flex;
  gap: 12px;
}

.back {
  width: 36px;
  height: 36px;
  background: #e0f5fb;
  border: 1px solid #b2e6f4;
  border-radius: 8px;
}

.controls {
  display: flex;
  gap: 8px;
}

.deleteControl {
  color: #FF4C4D;
  width: 141px;
  height: 36px;
  background: #FFF2F2;
  border: 1px solid #FF9999;
  border-radius: 8px;
}

.editControl {
  color: #158AA4;
  width: 161px;
  height: 36px;
  background: #E0F5FB;
  border: 1px solid #B2E6F4;
  border-radius: 8px;
}
