.legendContainer {
  max-width: 700px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  //align-items: center;
}

.legendColumn {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    width: 8px;
    height: 8px;
    display: block;
    border-radius: 50%;
  }

  p {
    font-weight: 500;
    font-size: 13px;
    color: #6E7191;
    margin: 0;
  }
}
