.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #E5E7EB;
    align-items: center;

    .headerInfo {
      display: flex;
      flex-direction: column;

      .exerciseName {
        color: #14142B;
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0.75px;
      }

      .patientName {
        color: #6E7191;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }

    .closeIcon {
      cursor: pointer;
      color: #4E4B66;
    }
  }

  .exercisesContainer {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    flex: 1;

    .controlsInfo {
      display: flex;
      flex-direction: column;

      span {
        color: #6E7191;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.75px;
      }
    }

    .assigned {
      display: flex;
      padding: 16px;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      border-radius: 8px;
      border: 1px solid;
      min-height: 360px;

      > span {
        color: #4E4B66;
        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.25px;
      }

      .assignedExerciseContainer {
        flex: 1;
        display: flex;
        align-items: center;

        .noAssignedExercisesWarning {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex: 1;
          gap: 24px;

          .warningText {
            display: flex;
            flex-direction: column;
            align-items: center;

            span {
              color: #6E7191;
              font-size: 15px;
              font-weight: 400;
              line-height: 24px;
              letter-spacing: 0.75px;
            }
          }
        }
      }

      .clearAllButton {
        align-items: flex-end;
      }
    }

    .unassigned {
      display: flex;
      padding: 16px;
      flex-direction: column;
      gap: 12px;
      flex: 1;
      border-radius: 8px;
      border: 1px dashed #D9DBE9;
      background: transparent;
      min-height: 270px;

      > span {
        color: #6E7191;
        font-size: 13px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0.25px;
      }
    }
  }

  .buttons {
    display: flex;
    align-self: flex-end;
    gap: 12px;
  }
}

.primaryButton {
  align-self: flex-end;
  width: 164px;
  height: 44px;
  padding: 4px 24px;
  border-radius: 8px;
  border: 1px solid #158AA4;
  background-color: #158AA4;
  color: #fff;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.secondaryButton {
  align-self: flex-end;
  width: 120px;
  height: 44px;
  padding: 4px 24px;
  border-radius: 8px;
  border: 1px solid #158AA4;
  background-color: transparent;
  color: #158AA4;

  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
}

.exerciseList {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  flex: 1;
  align-self: flex-start;
  width: 100%;
}
