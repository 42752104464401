.modalContainer {
  border-radius: 8px;
  min-width: 600px;
}

.modalHeader {
  padding: 24px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modalTitle {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.modalBody {
  color: #6b7280;
  margin-top: 24px;
  padding: 24px;

  p {
    margin: 0;
  }
}

.actions {
  padding: 24px;
  border-top: 1px solid #e5e7eb;
}

.action {
  width: 120px;
  height: 44px;
  border-radius: 8px;
}

.deleteAction {
  min-width: 150px;
  height: 44px;
  border-radius: 8px;
}
