.actionContainer {
  padding: 18px 16px;
  min-width: 167px;
  max-width: 220px;
  height: 81px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  background: #ffffff;
  border: 1px solid #f4f4f6;
  border-radius: 8px;
}

.info {
  & p:first-child {
    font-weight: 600;
    font-size: 13px;
    color: #262338;
    margin: 0;
  }

  & p:last-child {
    font-weight: 500;
    font-size: 13px;
    color: #A0A3BD;
    margin: 0;
  }
}

.action {
  margin: 0;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
