.navBarContainer {
  width: 220px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #f4f4f6;
  border-radius: 0px 18px 18px 0px;
  background: white;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.divider {
  width: 172px;
  margin: auto;
}

.logo {
  margin: 23px 24px 0 24px;
}

.footer {
  padding: 0 15px 20px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.userControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.userIcon,
.logoutButton {
  margin: 0;
  width: 40px;
  height: 40px;
  border: 1px solid #d9dbe9;
  border-radius: 8px;
}

.userIcon {
  text-align: center;
  padding-top: 10px;
}

.logoutButton {
  border: none;
}
