.clickingOverviewContainer {
  padding: 18px 20px 14px 20px;
  height: auto;
  min-height: 522px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #EFF0F7;
  border-radius: 8px;
}

.chartHeader {
  margin-bottom: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
